/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeI from "../../templates/PageTypeI"
import List from "../../components/List"

import TitleImg from "../../images/simcrest-apps-ebank-reconciliation.png"

import IconManual from "../../images/icon-manual.svg"
import IconLicense from "../../images/icon-license.svg"
import IconPrivacy from "../../images/icon-privacy.svg"

import BlueArrow from "../../images/blue-arrow.svg"

/***************************************************
  Page Data
***************************************************/

const summaryInfo = () => {
  return (
    <div className="simcrest-apps summary-info">
      <p className="spread">
        Reconciling your books to the bank statement can be a lot of time
        consuming work and frustation. It can be difficult to find and match the
        book entries to the bank statement, and making adjustments to your books
        can be a lot of going back and forth between the statement and the
        general ledger.
        <br />
        <br />
        Our E-Bank Reconciliation App allows you to download the statement from
        the bank and automatically reconcile it against your books. E-Bank also
        easily allows you to reconcile the few bank entries that it couldn’t
        match using an intuitive interface and within a few clicks you have the
        entire reconciliation done.
      </p>
      <span>Features and Benefits</span>
      <List
        flex
        className="concise dark"
        items={[
          {
            text: `Save a lot of time reconciling your bank.`,
          },
          {
            text: `Download the statement from your bank and let E-Bank automatically reconcile your books with the statement.`,
          },
          {
            text: `Easy user interface let you reconcile entries that didn’t match in minutes, not hours.`,
          },
          {
            text: `Allows adjustments to the reconciliation without having to leave the page.`,
          },
        ]}
      />
      <div>
        <img src={TitleImg} />
        <div>
          <p>
            SimCrest E-Bank Reconciliation is easy to use and saves you a lot of
            time!
            <br />
            <br />
            E-Bank Reconciliation can be acquired as an App in the Extension
            Market Place inside Dynamics 365 Business Central or as an On
            Premise App. Just click Free trial to install and try out the
            solution.
          </p>
          <div className="blue-arrow-link">
            <img className="blue-arrow" src={BlueArrow} alt="Arrow" />
            <a
              href="https://appsource.microsoft.com/en-us/product/dynamics-365-business-central/PUBID.sim_crest%7CAID.ebankrec%7CPAPPID.ac2e23e7-ca5a-464a-8934-218def078c0b"
              target="_blank"
            >
              See the App on Dynamics 365 Business Central
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeI
      title="E-Bank Reconciliation"
      description="E-Bank Reconciliation allows you to download the statement from the bank and automatically reconcile it against your books."
      linkSubPageGroupID="SimCrestAppsGroup"
      subTitle="E-Bank for Microsoft Dynamics 365 Business Central"
      summaryInfo={summaryInfo}
      videos={[
        {
          title: "Demo of E-Bank Reconciliation",
          embedCode: (
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/SUhiGRCGvV4"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          ),
        },
      ]}
      documents={[
        {
          title: "Manual",
          link: "/docs/manual-ebank.pdf",
          icon: IconManual,
        },
        {
          title: "License",
          link: "/docs/license-ebank.pdf",
          icon: IconLicense,
        },
        {
          title: "Privacy",
          link: "/docs/privacy-ebank.pdf",
          icon: IconPrivacy,
        },
      ]}
    />
  )
}
